import {FC, useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {Breakdown} from '../../../../app/modules/apps/user-management/users-list/core/_models'
import {Tooltip} from 'bootstrap'

type Props = {
  className: string
  chartHeight: number
  stats: Breakdown
  title?: string
  loading?: boolean
  useCustomColors?: boolean
  showLegend: boolean
}

export const HorizontalBarChart: FC<Props> = ({
  showLegend,
  className,
  stats,
  title,
  loading,
  chartHeight,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (loading || !chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(stats, showLegend))
    if (chart) {
      chart.render()
      Array.from(document.querySelectorAll('.tooltip-label:not([data-bs-toggle])')).forEach(
        (node) => {
          const text = node.querySelector('title')?.innerHTML || ''
          if (!text || !text.length) {
            return
          }
          node.setAttribute('data-bs-title', text)
          node.setAttribute('data-bs-toggle', 'tooltip')
          new Tooltip(node)
        }
      )
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, stats, loading])

  return (
    <div className={`card card-flush ${className}`}>
      {/* begin::Header */}
      <div className='card-header pt-5'>
        {/* begin::Title */}
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder text-dark'>{title}</span>
        </h3>
        {/* end::Title */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-0 px-0'>
        <div ref={chartRef} className='min-h-auto' style={{height: `${chartHeight}px`}}></div>
        {loading && (
          <div className='text-center'>
            <div
              className='spinner-border'
              role='status'
              style={{width: '6rem', height: '6rem', marginBottom: '100px'}}
            >
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (stats: Breakdown, showLegend: boolean): ApexOptions => {
  const borderColor = getCSSVariableValue('--bs-border-dashed-color')

  const colors = stats.map((stat) => stat.color || '#4FC9DA')

  return {
    series: [
      {
        name: 'Count',
        data: stats.map((stat) => stat.count),
      },
    ],
    chart: {
      type: 'bar',
      height: 300,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
        distributed: true,
        // barHeight: '23',
        dataLabels: {
          position: 'bottom',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: showLegend,
    },
    colors,
    xaxis: {
      tickAmount: 1,
      decimalsInFloat: 0,
      categories: stats.map((stat) => stat.name),
      labels: {
        style: {
          colors: getCSSVariableValue('--bs-gray-400'),
          fontSize: '14px',
          fontWeight: '600',
        },
        formatter: (value: string) => {
          return parseInt(value).toString()
        },
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: getCSSVariableValue('--bs-gray-800'),
          fontSize: '14px',
          fontWeight: '600',
          cssClass: 'tooltip-label',
        },
        offsetY: 2,
        align: 'left',
        show: !showLegend,
      },
    },
    grid: {
      borderColor: borderColor,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      strokeDashArray: 4,
    },
    tooltip: {
      // theme: 'dark',
      x: {
        show: true,
      },
      y: {
        formatter: function (value: number) {
          return value.toFixed(0)
        },
        title: {
          formatter: function () {
            return ''
          },
        },
      },
    },
  }
}
